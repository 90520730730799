import React from "react";
import "../style/WhatsAppChat.css";

const WhatsAppChat = () => {
  const phoneNumber = "123456789"; // Numărul de telefon WhatsApp

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      className="whatsapp-chat"
      target="_blank"
      rel="noopener noreferrer">
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp Chat"
        className="whatsapp-icon"
      />
    </a>
  );
};

export default WhatsAppChat;
