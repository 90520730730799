import React from "react";
import "../style/gdpr.css";

const GDPR = () => {
  return (
    <div className="gdpr-container">
      <h1>Politică de Protecție a Datelor Personale</h1>

      <h2>1. Introducere</h2>
      <p>
        Eliberariacte.ro, operat de RENEGADE GRIGORE S.R.L., respectă
        drepturile și protecția datelor cu caracter personal ale utilizatorilor
        săi conform Regulamentului General privind Protecția Datelor (GDPR) și
        legislației aplicabile în domeniul protecției datelor.
      </p>

      <h2>2. Datele colectate</h2>
      <p>
        Eliberariacte.ro colectează și prelucrează datele personale ale
        utilizatorilor săi în scopul furnizării serviciilor specifice oferite de
        site-ul nostru. Aceste date pot include, dar nu se limitează la: nume,
        adresă de email, număr de telefon, adresa poștală, informații de
        facturare și alte informații necesare pentru procesarea comenzilor și
        serviciilor.
      </p>

      <h2>3. Scopul colectării datelor</h2>
      <p>
        Datele personale colectate sunt utilizate în principal pentru
        următoarele scopuri:
      </p>
      <ul>
        <li>
          Procesarea comenzilor și furnizarea serviciilor solicitate de
          utilizatori;
        </li>
        <li>
          Comunicarea cu utilizatorii pentru a oferi asistență și răspunsuri la
          întrebări;
        </li>
        <li>
          Îmbunătățirea serviciilor și personalizarea experienței
          utilizatorilor;
        </li>
        <li>
          Respectarea obligațiilor legale și fiscale în conformitate cu
          legislația în vigoare;
        </li>
        <li>
          Păstrarea datelor personale doar pe perioada necesară pentru atingerea
          scopurilor menționate mai sus sau în conformitate cu cerințele legale
          aplicabile.
        </li>
      </ul>

      <h2>4. Securitatea datelor</h2>
      <p>
        Ne angajăm să protejăm datele personale ale utilizatorilor împotriva
        accesului neautorizat, divulgării sau distrugerii prin implementarea
        măsurilor de securitate adecvate.
      </p>

      <h2>5. Divulgarea datelor către terți</h2>
      <p>
        Datele personale colectate de eliberariacte.ro nu vor fi transferate
        către terți, cu excepția situațiilor în care acest lucru este necesar
        pentru a furniza serviciile solicitate de utilizatori. Orice transfer de
        date către terți va fi efectuat numai cu acordul explicit al
        utilizatorilor și în scopul de a duce solicitarea acestora mai departe,
        în conformitate cu cerințele legale și contractuale aplicabile. Echipa
        eliberariacte.ro se va asigura că terții implicați în prelucrarea
        datelor personale respectă standardele de confidențialitate și
        securitate corespunzătoare pentru a proteja datele utilizatorilor. Vom
        lua măsuri adecvate pentru a ne asigura că aceste transferuri de date
        sunt realizate în deplină conformitate cu GDPR și cu respectarea
        drepturilor utilizatorilor.
      </p>

      <h2>6. Drepturile utilizatorilor</h2>
      <p>
        În conformitate cu GDPR, utilizatorii au dreptul de a solicita
        informații privind datele personale stocate de eliberariacte.ro, de a
        rectifica datele incorecte, de a solicita ștergerea datelor personale
        sau restricționarea prelucrării, precum și de a se opune prelucrării
        datelor personale în anumite condiții. Pentru exercitarea acestor
        drepturi, utilizatorii pot contacta eliberariacte.ro prin intermediul
        adresei de email: contact@eliberariacte.ro.
      </p>

      <h2>7. Modificări ale Politicii de Confidențialitate</h2>
      <p>
        Această Politică de Confidențialitate poate fi actualizată periodic
        pentru a reflecta schimbările aduse serviciilor noastre sau modificările
        legislative. Orice modificare semnificativă a Politicii de
        Confidențialitate va fi adusă la cunoștința utilizatorilor în mod
        transparent și în timp util. Prin continuarea utilizării serviciilor
        noastre după aceste modificări, vă exprimați acordul cu noile prevederi
        ale Politicii de Confidențialitate.
      </p>

      <h2>8. Contact</h2>
      <p>
        Dacă aveți întrebări, nelămuriri sau doriți să exercitați drepturile
        dumneavoastră cu privire la datele personale, vă rugăm să ne contactați
        prin intermediul adresei de email: contact@eliberariacte.ro. Echipa
        noastră vă stă la dispoziție pentru a vă oferi informațiile și asistența
        necesare.
      </p>

      <p>Data ultimei actualizări: 06/08/2024</p>
      <p>
        Vă încurajăm să citiți cu atenție această Politică de Confidențialitate
        și să ne contactați dacă aveți întrebări sau nelămuriri.
      </p>
    </div>
  );
};

export default GDPR;
