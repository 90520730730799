import React from "react";
import "../style/cookies.css";

const CookiePolicy = () => {
  return (
    <div className="cookie-policy-container" id="cookies">
      <h1>Politica de Cookie-uri</h1>

      <h2>Ce sunt cookie-urile?</h2>
      <p>
        Un cookie este o mică bucată de text pe care serverul nostru web îl
        stochează pe computerul sau dispozitivul dvs. mobil, pe care browserul
        dvs. ni-l trimite când reveniți pe site-ul nostru. Cookie-urile nu vă
        identifică neapărat dacă vizitați doar site-ul nostru web; cu toate
        acestea, un cookie poate stoca un identificator unic pentru fiecare
        utilizator conectat. Cookie-urile pe care le folosim sunt esențiale
        pentru funcționarea site-ului web sau sunt utilizate în scopuri de
        performanță, funcționalitate și securitate.
      </p>
      <p>
        De exemplu, cookie-urile ne permit să ne amintim lucruri despre vizita
        dumneavoastră pe site-ul nostru web, cum ar fi limba preferată, fusul
        orar și alte preferințe, pentru a face site-ul și serviciul mai ușor de
        utilizat.
      </p>
      <p>
        Pentru a oferi cel mai înalt nivel de utilizare, performanță și
        securitate, folosim atât cookie-uri de sesiune, cât și cookie-uri
        persistente. Un cookie persistent constă dintr-un fișier text trimis de
        un server web către un browser web, care va fi stocat de browser și va
        rămâne valabil până la data de expirare stabilită (cu excepția cazului
        în care este șters de către utilizator înainte de data de expirare). Un
        cookie de sesiune, pe de altă parte, va expira la sfârșitul sesiunii de
        utilizator, când browserul web este închis.
      </p>

      <h2>Cum și de ce folosim cookie-uri și tehnologii similare</h2>
      <p>
        Cookie-urile și tehnologiile similare pe care le folosim, inclusiv web
        beacons, cookie-uri de browser, etichete de pixeli și obiecte locale
        partajate (numite și „cookie-uri flash”) au toate un obiectiv: de a
        oferi, măsura și îmbunătăți toate aspectele serviciului nostru în mod
        continuu și în diferite moduri. Facem diferența între acele cookie-uri
        care sunt strict necesare pentru acces și navigare, altele care urmăresc
        utilizarea (cookie-uri de performanță), își amintesc opțiunile
        (cookie-uri de funcționalitate) și cookie-uri care vă oferă conținut sau
        publicitate țintită.
      </p>
      <p>
        Utilizăm cookie-uri în următoarele scopuri și în conformitate cu
        articolul 6 alin. 1 f GDPR:
      </p>
      <h3>1. Acces și securitate (cookie-uri strict necesare)</h3>
      <ul>
        <li>
          Pentru a vă recunoaște computerul atunci când vizitați site-ul nostru
          web și pentru a facilita conectarea;
        </li>
        <li>
          Pentru a vă proteja securitatea și a lupta împotriva spamului și a
          abuzului site-ului și serviciului nostru;
        </li>
        <li>Pentru a împiedica terții neautorizați să vă acceseze contul.</li>
      </ul>

      <h3>2. Setări (cookie-uri de funcționalitate)</h3>
      <p>
        Pentru a reține detalii despre browserul dvs., setările site-ului și
        preferințele pe care le-ați specificat în legătură cu utilizarea
        site-ului și serviciilor noastre (de exemplu, limba preferată sau fusul
        orar).
      </p>

      <h3>3. Analiză și cercetare (cookie-uri de performanță)</h3>
      <p>
        Pentru a ne ajuta să obținem informații importante și să înțelegem modul
        în care utilizatorii folosesc site-ul și serviciul nostru.
      </p>

      <h3>4. Publicitate și marketing (alte cookie-uri)</h3>
      <ul>
        <li>Pentru a personaliza site-ul web și serviciul pentru dvs.;</li>
        <li>
          Pentru a include reclame, care pot fi de interes special pentru dvs.
        </li>
      </ul>
      <p>
        În efortul nostru de a înțelege corect modul în care utilizatorii
        folosesc site-ul și serviciul nostru, am implementat o serie de
        parteneri de analiză, inclusiv Google Analytics. Acești parteneri
        folosesc cookie-uri pentru a colecta informații și a analiza navigarea
        pe site și cum și de pe ce site-uri web au ajuns utilizatorii site-ului
        și serviciului nostru.
      </p>
      <p>
        Nu dezvăluim nicio informație colectată din propriile cookie-uri către
        niciun furnizor terță parte, așteptăm ca furnizorii de servicii să
        asiste la gestionarea cookie-urilor.
      </p>

      <h2>
        Ce pot face pentru a controla modul în care sunt utilizate cookie-urile?
      </h2>
      <p>
        Puteți dezactiva instalarea cookie-urilor introducând setările
        corespunzătoare în software-ul browserului dumneavoastră (de exemplu, în
        Internet Explorer, Mozilla Firefox, Opera sau Safari). Pentru a renunța
        la Google Analytics, puteți instala Programul de renunțare la Google
        Analytics, disponibil aici:{" "}
        <a
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noopener noreferrer">
          https://tools.google.com/dlpage/gaoptout
        </a>
        .
      </p>
      <p>
        Pentru informații generale despre cookie-uri și despre cum să le
        dezactivați, vă rugăm să vizitați{" "}
        <a
          href="https://www.allaboutcookies.org"
          target="_blank"
          rel="noopener noreferrer">
          www.allaboutcookies.org
        </a>
        .
      </p>

      <h2>Modificări ale acestei politici</h2>
      <p>
        Deși schimbările semnificative sunt rare, această politică poate fi
        modificată pe măsură ce apar noi caracteristici, tehnologii sau cerințe
        legale, așa că vă rugăm să verificați din când în când. Vă vom anunța
        dacă facem o schimbare semnificativă și, dacă este necesar, vă vom
        solicita consimțământul.
      </p>

      <h2>Întrebări</h2>
      <p>
        Dacă aveți întrebări despre utilizarea cookie-urilor, vă rugăm să nu
        ezitați să ne contactați.
      </p>
    </div>
  );
};

export default CookiePolicy;
