import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../assets/logo.png";
import "../style/navbar.css";
import { Link as ScrollLink } from "react-scroll";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };

  const handleLinkClick = () => {
    setIsMobile(false);
  };

  const isHomePage = location.pathname === "/";

  const handleDocumentClick = () => {
    if (window.innerWidth >= 768) {
      // Deschide dropdown-ul pe desktop
      setIsDropdownOpen((prev) => !prev);
    } else {
      // Închide meniul mobil când se face scroll
      setIsMobile(false);
    }
  };

  // Închide dropdown-ul când se face click în afara acestuia
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".dropdown")) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        {isHomePage ? (
          <ScrollLink
            className="button-navbar"
            to="home"
            smooth={true}
            duration={500}
            onClick={handleLinkClick}>
            <img className="image-logo" src={Logo} alt="eliberariacte.ro" />
          </ScrollLink>
        ) : (
          <NavLink className="button-navbar" to="/" onClick={handleLinkClick}>
            <img className="image-logo" src={Logo} alt="eliberariacte.ro" />
          </NavLink>
        )}
      </div>

      <ul className={`nav-links ${isMobile ? "nav-links-mobile open" : ""}`}>
        <li>
          {isHomePage ? (
            <ScrollLink
              className="button-navbar"
              to="home"
              onClick={handleLinkClick}>
              Acasă
            </ScrollLink>
          ) : (
            <NavLink className="button-navbar" to="/" onClick={handleLinkClick}>
              Acasă
            </NavLink>
          )}
        </li>
        <li>
          {isHomePage ? (
            <ScrollLink
              className="button-navbar"
              to="about-new"
              onClick={handleLinkClick}>
              Despre
            </ScrollLink>
          ) : (
            <NavLink
              className="button-navbar"
              to="/#about"
              onClick={handleLinkClick}>
              Despre
            </NavLink>
          )}
        </li>
        <li className="dropdown">
          {isHomePage ? (
            <ScrollLink
              className="button-navbar"
              to="services"
              onClick={handleDocumentClick}>
              Documente
            </ScrollLink>
          ) : (
            <span className="button-navbar" onClick={handleDocumentClick}>
              Documente
            </span>
          )}
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <NavLink to="cazier-judiciar" className="dropdown-item">
                Cazier
              </NavLink>
              <NavLink to="certificat-constatator" className="dropdown-item">
                Certificat Constatator
              </NavLink>
              <NavLink to="extras-plan-cadastral" className="dropdown-item">
                Plan Cadastral
              </NavLink>
              <NavLink to="extras-carte-funciara" className="dropdown-item">
                Carte Funciară
              </NavLink>
            </div>
          )}
          {isMobile && (
            <div className="mobile-footer">
              <div className="divider"></div>
              <div className="contact-info">
                <a href="mailto:contact@eliberariacte.ro">
                  contact@eliberariacte.ro
                </a>
                <span>|</span>
                <a href="tel:+40 743 406 713">+40 743 406 713</a>
              </div>
            </div>
          )}
        </li>
        <li>
          {isHomePage ? (
            <ScrollLink
              className="button-navbar"
              to="contact"
              onClick={handleLinkClick}>
              Contact
            </ScrollLink>
          ) : (
            <NavLink
              className="button-navbar"
              to="/#contact"
              onClick={handleLinkClick}>
              Contact
            </NavLink>
          )}
        </li>
      </ul>

      <div className="left-side-navbar">
        <ScrollLink className="btn" to="services" onClick={handleLinkClick}>
          OBȚINE DOCUMENT
        </ScrollLink>

        <div className="hamburger" onClick={handleToggle}>
          {isMobile ? (
            <FaTimes size={30} className="hamburger-icon" />
          ) : (
            <FaBars size={30} className="hamburger-icon" />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
