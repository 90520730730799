import React from "react";
import "../style/termeni.css"; // Assuming you have a separate CSS file for styling

const TermeniSiConditii = () => {
  return (
    <div className="terms-container">
      <h1 className="title-terms">Termeni și Condiții (versiunea 2.1 – 01.03.2024)</h1>

      <h2>1. Părțile Contractante</h2>
      <p>
        1.1. RENEGADE GRIGORE SRL, cu sediul în Bucureşti, Sectorul 1, Strada
        Stanilesti, nr. 56, Cam. 1, Număr de ordine în Registrul Comerţului:
        _________, Cod unic de înregistrare: _________, denumit în continuare
        Prestatorul;
      </p>
      <p>
        1.2. Clientul sau Utilizatorul: persoana fizică, persoana juridică sau
        orice altă entitate juridică care interacționează cu Platforma, își
        creează un cont și/sau plasează o comandă, au agreat următoarele:
      </p>

      <h2>Definiții</h2>
      <p>
        Platformă/Website: eliberariacte.ro; Email: contact@eliberariacte.ro;
        Client: persoana fizică, persoana juridică sau orice altă entitate
        juridică care plasează o comandă pe Website; Utilizator: orice persoană
        care vizitează și/sau interacționează cu Website-ul; Serviciu: serviciul
        de comerț electronic condus exclusiv pe porțiunile public disponibile
        ale Website-ului, în sensul acordării posibilității Utilizatorului să
        contracteze servicii folosind mijloace exclusiv electronice, incluzând
        și alte mijloace de comunicare la distanță; Conținut: toate informațiile
        aflate pe Website care pot fi vizitate, vizualizate sau altfel accesate
        prin utilizarea unui echipament numeric, respectiv:
      </p>
      <ul>
        <li>
          conținutul oricărui e-mail trimis Utilizatorilor sau Clienților săi de
          către Prestator prin mijloace electronice și/sau orice alt mijloc de
          comunicare disponibil;
        </li>
        <li>
          orice informație comunicată prin orice mijloc de către un
          angajat/representant/colaborator al Prestatorului către Client,
          conform informațiilor de contactare, specificate sau nu de către
          acesta;
        </li>
        <li>
          informații legate de serviciile și/sau tarifele practicate de
          Prestator într-o anumită perioadă;
        </li>
        <li>
          date referitoare la Prestator sau alte date privilegiate ale acestora.
        </li>
      </ul>
      <p>
        Servicii: orice serviciu pus la dispoziția Clientului prin intermediul
        Website-ului și care urmează a fi furnizate după încheierea
        Contractului; Comandă: document electronic ce intervine ca formă de
        comunicare între Prestator și Utilizator prin care Utilizatorul
        transmite Prestatorului, prin intermediul Website-ului intenția sa de a
        achiziționa Servicii de pe Website; Specificații: toate specificațiile
        și/sau descrierile, așa cum sunt menționate/descrise pe Website;
        Contract: plasarea unei Comenzi valide de către Client, și acceptarea
        acesteia de către Prestator; Contract la distanță: orice contract
        încheiat între profesionist și consumator în cadrul unui sistem de
        vânzări sau de prestare de servicii la distanță organizat, fără prezența
        fizică simultană a profesionistului și a consumatorului (sau a
        prestatorului și beneficiarului), cu utilizarea exclusivă a unuia sau a
        mai multor mijloace de comunicare la distanță, până la și inclusiv în
        momentul în care este încheiat contractul; Tranzacție: încasare sau
        rambursare a unei sume rezultată din vânzarea unui Serviciu de către
        Prestator către Client, prin utilizarea serviciilor procesatorului de
        carduri agreat de către Prestator, indiferent de modalitatea de livrare;
        Document: Prezentele Termeni și Condiții.
      </p>

      <h2>2. Descrierea și Condițiile de Utilizare ale Serviciilor</h2>
      <p>
        2.1. Acord. Prin utilizarea Website-ului, este necesar ca Utilizatorul
        să fie de acord cu Termenii și Condițiile Prestatorului.
        Utilizatorul/Clientul trebuie să citească informațiile cu atenție.
        Informarea Utilizatorului/Clientului cu privire la prezentele Termeni și
        condiții va preceda orice act sau fapt de natură să determine obligații
        pentru părțile contractante. Vizitarea și utilizarea Website-ului sunt
        condiționate de acceptarea acestor condiții de utilizare. Acest Document
        este propus de Prestator și trebuie acceptat înainte de comandarea
        oricărui serviciu oferit de Prestator. Orice comandă confirmată de
        Client, reprezintă o acceptare din partea Clientului a ofertei
        Prestatorului și a acestui contract, în condițiile stipulate de art. 9
        din Legea nr. 365/2002 privind comerțul electronic.
      </p>
      <p>
        2.2. Caracterul obligatoriu. Folosirea Serviciilor implică aderarea
        Utilizatorului/Clientului la prezentele Termeni și Condiții prin
        acceptarea expresă a prezentului Document. Dacă una dintre prevederile
        acestui act se vădește a fi nulă sau invalidă, toate celelalte rămân
        valabile, pe cât posibil. În cazul în care se fac referiri la alte
        Website-uri, Prestatorul nu garantează și/sau confirmă sub nicio formă
        tipul de informație pe care Utilizatorul/Clientul îl va găsi pe acestea.
      </p>
      <p>
        2.3. Accesul la Serviciile Prestatorului se face exclusiv prin accesarea
        Website-ului public. Prin folosirea
        Website-ului/Conținutului/Serviciilor, Utilizatorul/Clientul este
        singurul responsabil pentru toate activitățile care decurg prin
        folosirea acestuia. De asemenea, acesta răspunde pentru orice daune
        materiale, intelectuale sau electronice sau de orice altă natură produse
        Website-ului, Conținutului, Serviciilor, societății Prestatorului sau
        oricărui terț cu care Prestatorul are încheiate contracte, în
        conformitate cu legislația română în vigoare.
      </p>
      <p>
        2.4. Clientul/Utilizatorul se obligă să ia cunoștință de aceste condiții
        înainte de a realiza o comandă sau de a-și deschide un cont. Toate
        comenzile și deschiderea contului implică acceptarea de către acesta a
        tuturor acestor Condiții. Prezentul Document este aplicabil tuturor
        comenzilor sau conturilor realizate prin intermediul Website-ului.
      </p>
      <p>
        Serviciile pot include posibilitatea de a obține diverse documente
        precum:
      </p>
      <ul>
        <li>
          Extras de carte funciară: Acest serviciu permite obținerea de
          informații despre un imobil înregistrat în cartea funciară, inclusiv
          date despre proprietar, suprafață, drepturi reale, sarcini sau alte
          informații relevante.
        </li>
        <li>
          Extras de plan cadastral: Cu ajutorul acestui serviciu, se poate
          obține un extras din planul cadastral, care conține informații
          referitoare la delimitarea și identificarea precisă a parcelelor de
          teren în zona respectivă.
        </li>
        <li>
          Certificat Constatator: Acest certificat furnizează informații despre
          o societate înregistrată, precum denumirea, adresa sediului, codul
          unic de înregistrare, forma juridică și alte detalii relevante.
        </li>
        <li>
          Cazier Judiciar: Acest serviciu oferă posibilitatea de a obține
          informații privind cazierul judiciar al unei personae fizice sau al
          unei persoane juridice, inclusiv condamnări, infracțiuni sau alte
          evenimente relevante.
        </li>
      </ul>
      <p>
        2.5. Documentele de mai sus sunt obținute prin oferirea unor servicii
        pentru pregătirea documentelor alături de parteneriate cu
        avocați/juriști specializați. Fiecare operațiune necesită în principiu
        aceiași pași, însă pentru a afla cum funcționează în mod concret, va
        trebui să verificați paginile Website-ului special destinate fiecărui
        serviciu oferit.
      </p>

      <h2>3. Conținut</h2>
      <p>
        3.1. Conținutul, astfel cum este definit în preambul, incluzând dar
        nelimitându-se la logo-uri, reprezentări stilizate, simboluri
        comerciale, imagini statice, imagini dinamice, text și/sau conținut
        multimedia prezentate pe Website, sunt proprietatea exclusivă a
        Prestatorului, acestora fiindu-le rezervate toate drepturile obținute în
        acest sens în mod direct sau indirect (prin licențe de utilizare și/sau
        publicare).
      </p>
      <p>
        3.2. Utilizatorului sau Clientului nu îi este permisă copierea,
        distribuirea, publicarea, transferul către terțe părți, modificarea
        și/sau altfel alterarea, utilizarea, legarea la, expunerea, includerea
        oricărui conținut în orice alt context decât cel original intenționat de
        Prestator, includerea oricărui conținut în afara Website-ului,
        îndepărtarea însemnelor care semnifică dreptul de autor al Prestatorului
        sau asupra conținutului, precum și participarea la transferul, vânzarea,
        distribuția unor materiale realizate prin reproducerea, modificarea sau
        afișarea conținutului, decât cu acordul expres al Prestatorului.
      </p>
      <p>
        3.3. Orice Conținut la care Utilizatorul sau Clientul are și/sau obține
        acces prin orice mijloc, se află sub incidența Documentului, în cazul în
        care Conținutul nu este însoțit de un acord de utilizare specific și
        valid încheiat între Prestator și acesta, și fără nicio garanție
        implicită sau expres formulată din partea Prestatorului cu referire acel
        conținut.
      </p>
      <p>
        3.4. Utilizatorul sau Clientul poate copia, transfera și/sau utiliza
        conținut numai în scopuri personale sau necomerciale, numai în cazul în
        care acestea nu intră în conflict cu prevederi ale Documentului și cu
        acordul Prestatorului.
      </p>
      <p>
        3.5. În cazul în care Prestatorul conferă Utilizatorului sau Clientului
        dreptul de a utiliza sub forma descrisă într-un acord de utilizare
        distinct un anumit conținut la care Utilizatorul are sau obține acces în
        urma acestui acord, acest drept se extinde numai asupra acelui sau
        acelor conținuturi definite în acord, numai pe perioada existenței
        acestuia sau acestor conținuturi pe Website sau a perioadei definite în
        acord, conform condițiilor definite, în cazul în care acestea există și
        nu reprezintă un angajament contractual din partea Prestatorului pentru
        respectivul Utilizator / Client sau oricare alt terț care are/obține
        acces la acest conținut transferat, prin orice mijloc și care ar putea
        fi sau este prejudiciat în orice mod de pe urma acestui conținut, în
        timpul sau după expirarea acordului de utilizare.
      </p>
      <p>
        3.6. Niciun conținut transmis către Utilizator sau Client, prin orice
        mijloc de comunicare (electronic, telefonic etc.) sau dobândit de acesta
        prin accesare, vizitare și/sau vizualizare nu constituie o obligație
        contractuală din partea Prestatorului și/sau al angajatului
        Prestatorului sau al celui care a mijlocit transferul de conținut, în
        cazul în care aceasta există, față de respectivul conținut.
      </p>
      <p>
        3.7. Este interzisă orice utilizare a Conținutului în alte scopuri decât
        cele permise expres prin Document sau de acordul de utilizare care îl
        însoțește, în cazul în care acesta există.
      </p>
      <p>
        3.8. Prestatorul își rezervă dreptul de a modifica sau de a adăuga noi
        reguli și restricții în ceea ce privește conținutul Website-ului, în
        orice moment.
      </p>

      <h2>4. Cesionarea și Subcontractarea</h2>
      <p>
        4.1. Prestatorul poate cesiona și/sau subcontracta o terță parte pentru
        servicii ce țin de onorarea Comenzii, cu informarea Clientului, nefiind
        necesar acordul acestuia. Prestatorul va fi întotdeauna responsabil față
        de Client pentru toate obligațiile contractuale.
      </p>

      <h2>5. Comanda</h2>
      <p>
        5.1. Prin lansarea unei comenzi și acceptarea Termenilor și Condițiilor
        (adică a prezentului Document), precum și prin aplicarea semnăturii sale
        în fluxul din platforma Website-ului prin care se face înregistrarea
        contului, Clientul își dă acordul și cu privire la:
      </p>
      <ul>
        <li>
          Semnarea unui contract de asistență juridică și a unei împuterniciri
          avocațiale cu PETRE ROSU SI ASOCIATII SOCIETATE CIVILĂ DE AVOCAȚI,
          prin care societatea de avocați este împuternicită de către Client sau
          de către persoana juridică (în cazul SRL-urilor) să îl reprezinte pe
          acesta în fața autorităților statului (Inspectoratul Județean de
          Poliție, Secțiile de Poliție, Serviciul cazier judiciar precum și în
          fața altor autorități sau instituții publice), pentru realizarea
          următoarelor activități: reprezentare juridică, redactare, semnare,
          depunere și ridicare documente în vederea obținerii certificatului de
          cazier judiciar și/sau a certificatului de integritate
          comportamentală.
        </li>
        <li>
          Împuternicirea PETRE ROSU SI ASOCIATII SOCIETATE CIVILĂ DE AVOCAȚI să
          solicite serviciile ce necesită aprobări speciale, de la profesioniști
          autorizați în acest sens.
        </li>
      </ul>
      <p>
        5.2. Tot prin lansarea unei comenzi și acceptarea Termenilor și
        Condițiilor, precum și prin aplicarea semnăturii sale în pasul
        corespunzător din fluxul urmat de Client în Platformă prin care se face
        înregistrarea contului, Clientul își dă acordul cu privire la faptul că
        semnătura sa va fi aplicată pe documentele menționate la pct.5.1.
      </p>
      <p>
        5.3. Documentele de împuternicire ale avocatului care vor fi transmise
        către autoritățile statului în toate situațiile și demersurile pentru
        care acesta a fost împuternicit pot fi consultate mai jos:
      </p>
      <ul>
        <li>Contractul de asistență juridică - vezi model</li>
        <li>Împuternicirea avocațială - vezi model</li>
      </ul>
      <p>
        5.4. Clientul este de acord ca o parte din suma achitată de acesta
        pentru serviciile contractate în vederea obținerii certificatului de
        cazier judiciar și/sau a certificatului de integritate comportamentală,
        reprezintă onorariul avocațial plătit de acesta și/sau de către persoana
        juridică (în cazul SRL-urilor) pentru reprezentarea în fața
        autorităților statului menționate la pct. 5.1. și acceptă ca această
        sumă să fie colectată de către Platformă și virată societății civile de
        avocați PETRE ROSU SI ASOCIATII. Suma respectivă va fi prevăzută în
        contractul de asistență juridică.
      </p>
      <p>
        5.5. Uneori, datorită numărului mare de cereri și a lipsei personalului
        de specialitate, autoritățile statului își exercită cu întârziere unele
        îndatoriri, iar regulile pe baza cărora funcționează acestea nu sunt
        clare și nici nu sunt aplicate consecvent, Prestatorul nu poate garanta
        un termen maxim de furnizare a serviciilor contractate și nu este
        răspunzător de termenul în care se soluționează cererile de către
        autoritățile statului. Prestatorul nu va fi răspunzător pentru oricare
        prelungire, întârziere sau respingere în soluționarea serviciilor
        contractate de Client și nici pentru eventualele consecințe pe care o
        astfel de prelungire, întârziere sau respingere o poate avea asupra
        activității Clientului.
      </p>
      <p>
        5.6. În momentul în care Clientul comandă unul dintre serviciile
        noastre, trebuie să completeze informațiile solicitate prin formularul
        de comandă corespunzător fiecărui serviciu. Aceste informații sunt
        necesare pentru procesarea corectă și eficientă a comenzii plasate de
        către Client. După plasarea comenzii, se va emite o confirmare în acest
        sens pe email-ul Clientului, situație ce atestă fermitatea cererii
        Clientului și implicit acceptarea condițiilor contractuale.
      </p>

      <h2>6. Modalități de Plată</h2>
      <p>
        6.1. Toate prețurile afișate pe Website includ TVA. Plata se poate
        realiza prin virament bancar sau online, în funcție de sistemul existent
        la momentul comenzii efectuate de către Client. Datele completate de
        Client sunt în siguranță, întrucât Prestatorul nu are acces la datele
        bancare ale Clientului.
      </p>
      <p>
        6.2. Prestatorul va putea publica pe Website informații despre servicii
        și/sau promoții practicate de către acesta sau de către oricare alt terț
        cu care acesta are încheiate contracte de parteneriat, într-o anumită
        perioadă.
      </p>
      <p>
        6.3. Prestatorul poate limita capacitatea de achiziționare a unor
        servicii disponibile pe Website la un moment dat, unuia sau mai multor
        Clienți.
      </p>
      <p>
        6.4. În cazul plăților online Prestatorul nu este și nu poate fi făcut
        responsabil pentru niciun alt cost suplimentar suportat de Client,
        incluzând dar nelimitându-se la comisioane bancare, comisioane de
        conversie valutară aplicate de către banca emitentă a cardului acestuia,
        în cazul în care moneda de emitere a acestuia diferă de RON.
        Responsabilitatea pentru această acțiune o poartă numai Clientul.
      </p>
      <p>
        6.5. Toate informațiile folosite pentru descrierea serviciilor
        disponibile pe Website (imagini statice / dinamice / prezentări
        multimedia / etc.) nu reprezintă o obligație contractuală din partea
        Prestatorului, acestea fiind cu titlu de prezentare.
      </p>
      <p>
        6.6. În descrierea serviciilor, Prestatorul își rezervă dreptul de a
        utiliza și alte servicii (accesorii / etc.) care pot să nu fie incluse
        în costurile serviciilor respective.
      </p>

      <h2>7. Disponibilitatea Serviciilor Afișate</h2>
      <p>
        7.1. Oferta Prestatorului cu privire la servicii și prețul acestora este
        valabilă atâta vreme cât este afișată pe Website sau a fost convenită de
        părți în scris. Prestatorul își va îndeplini obligațiile contractuale
        imediat ce Clientul va efectua plata aferentă Serviciului
        selectat/convenit. Orice modificări ale tarifelor sau cu privire la
        disponibilitatea serviciilor vor fi comunicate prin email la adresa de
        contact a Clientului din contul de utilizator.
      </p>
      <p>
        7.2. Prestatorul depune toate eforturile pentru a oferi permanent
        accesul la serviciile oferite. Perioada de disponibilitate nu include
        perioadele de mentenanță anunțate în prealabil prin intermediul
        platformei, însă include acele perioade de mentenanță neanunțate. Ca
        atare, având în vedere dependența serviciilor oferite de accesul la
        Internet, posibilele limitări tehnice și de sistem ce pot apărea,
        Prestatorul își asumă o obligație de diligență în ceea ce privește
        disponibilitatea serviciilor.
      </p>
      <p>
        7.3. În condițiile în care Serviciul dorit nu mai este disponibil, din
        motive imputabile Prestatorului, informațiile Clientului din baza de
        date a Prestatorului vor fi exportate și transmise acestuia.
      </p>

      <h2>8. Prețuri</h2>
      <p>
        8.1. Prețurile sunt stabilite atunci când produsele sunt afișate pe
        site, sunt exprimate în RON și includ TVA. Prețul afișat se poate
        modifica fără o notificare prealabilă pe Website-ul Prestatorului.
        Prețul obținerii unui cazier judiciar și/sau a certificatului de
        integritate comportamentală mergând personal la ghișeu fizic al
        Inspectoratului Județean de Poliție sau la Secțiile de Poliție din
        România este 0 (zero) lei. Serviciile oferite de Prestator sunt însă
        servicii plătite, având în vedere costurile privind administrarea
        Platformei, reprezentarea juridică, redactarea cererilor, semnarea,
        depunerea, ridicare și transmiterea documentelor în vederea obținerii
        certificatului de cazier judiciar și/sau a certificatului de integritate
        comportamentală.
      </p>
      <p>
        8.2. Serviciul Rapid. Serviciul Rapid este disponibil doar în zilele
        lucrătoare, în intervalul orar 08:00-19:00. Acest serviciu intră în
        vigoare în momentul în care Prestatorul primește documentul de la
        autoritățile statului. Prin acceptarea prezentului Document, Clientul
        confirmă că înțelege faptul că, în anumite situații, din cauze tehnice
        sau din cauza volumului mare de comenzi, termenul de livrare rapidă
        poate să nu fie respectat pentru o comandă specifică. În astfel de
        cazuri, taxa plătită pentru serviciul rapid nu va fi returnată. Clienții
        sunt încurajați să efectueze comenzi cu serviciul rapid în cunoștință de
        cauză și să ia în considerare posibilitatea unor întârzieri rare dar
        posibile în livrare.
      </p>
      <p>
        8.3. Timpul de Livrare și de procesare al comenzilor pentru cazierul
        judiciar și/sau a certificatului de integritate comportamentală.
        Opțiunile selectate de Client, cum ar fi traducerea legalizată sau
        apostila Haga, pot afecta timpul de livrare al cazierului judiciar
        și/sau a certificatului de integritate comportamentală, chiar și în
        regimul rapid. Clientul va lua în considerare aceste cerințe
        suplimentare atunci când plasează o comandă, deoarece ele pot influența
        durata totală de procesare. Platforma transmite solicitările către
        autoritățile statului de emitere a cazierului judiciar și/sau a
        certificatului de integritate comportamentală, respectând un program
        specific de procesare. Astfel, comenzile primite în intervalul de
        program, de luni până joi între orele 08:00 - 12:00 și vineri între
        08:00 - 11:00, vor fi procesate în aceeași zi lucrătoare. În cazul
        comenzilor plasate după încheierea programului, acestea vor fi procesate
        în următoarea zi lucrătoare.
      </p>
      <p>
        8.4. Rambursarea Plăților. În momentul în care comanda a fost
        înregistrată și a fost trimisă către autoritățile statului, Prestatorul
        nu va putea efectua rambursări ale plăților, indiferent de motiv.
        Această regulă se aplică inclusiv în cazurile în care Clientul a
        completat formularul incorect sau în alte circumstanțe neprevăzute.
        Clientul va acorda o atenție deosebită informațiilor furnizate și va
        verifica cu atenție datele înainte de finalizarea comenzii, astfel încât
        să fie evitate eventuale neconformități sau erori care ar putea duce la
        imposibilitatea rambursării plății.
      </p>

      <h2>9. Drepturile și Obligațiile Părților</h2>
      <h3>9.1. Drepturile și obligațiile Clientului</h3>
      <p>
        9.1.1. Clientul se obligă să folosească serviciile oferite de Prestator
        în deplină legalitate și cunoscând dispozițiile legale în vigoare.
      </p>
      <p>
        9.1.2. Clientul se obligă să respecte în totalitate prevederile legate
        de drepturile de autor și protecția datelor cu caracter personal în ceea
        ce privește datele introduse în sistem. Este INTERZISĂ solicitarea
        cazierului judiciar și/sau a certificatului de integritate
        comportamentală în numele altor persoane fără cunoștința și acordul
        acestora pe Website, acest fapt putând fi pedepsit cu închisoare de la 1
        la 5 ani, conform art. 325 din Codul penal privind falsul informatic.
      </p>
      <p>
        9.1.3. Clientul se obligă ca documentele transmise în format electronic
        să corespundă cu originalul, iar informațiile furnizate să fie reale și
        corecte, sub sancțiunea prevederilor art. 322 din Codul penal privind
        falsul în înscrisuri. În cazul în care documentele și informațiile puse
        la dispoziția Prestatorului nu sunt corecte și reale, Clientul își asumă
        toată răspunderea civilă, contravențională și penală ce decurge din
        acest lucru, iar, în caz de suspiciune că documentele și/sau
        informațiile primite de către Prestator nu sunt conforme cu realitatea,
        acesta are dreptul de a suspenda serviciile furnizate și/sau de a înceta
        colaborarea cu Clientul.
      </p>

      <h3>9.2. Drepturile și obligațiile Prestatorului</h3>
      <p>
        9.2.1. Prestatorul asigură Clientului: posibilitatea de a crea un cont
        de utilizator și de a încărca documente pentru serviciile oferite;
        asistență pe email de luni până vineri între orele 09:00 – 18:00; backup
        al datelor; conectarea la sistem printr-un protocol sigur de comunicare
        (https).
      </p>
      <p>
        9.2.2. Prestatorul nu își asumă responsabilitatea pentru pierderea
        parolelor de acces la contul de utilizator sau pentru activități ce pot
        compromite contul Clientului. În cazul în care Prestatorul primește o
        notificare cu privire la existența unor servicii cu o aparență nelegală
        realizată de către Client, își rezervă dreptul de a suspenda contul de
        utilizator sau bloca accesul la acesta.
      </p>
      <p>
        9.2.3. Prestatorul nu exercită niciun control asupra datelor și
        documentelor furnizate de Client.
      </p>
      <p>
        9.2.4. Prestatorul are dreptul de a colecta date anonime despre modul de
        utilizare a serviciilor sale și de a le face publice în mod agregat.
      </p>

      <h2>10. Răspunderea Contractuală</h2>
      <p>
        10.1. Clientul garantează realitatea datelor introduse și poartă
        întreaga responsabilitate pentru acestea. Clientul trebuie să fie extrem
        de atent și să completeze cu precizie toate datele solicitate în
        formularul de comandă. Procesul pe platforma Prestatorului este
        automatizat și se bazează pe informațiile furnizate de Client pentru a
        procesa comenzile. Din acest motiv, este esențial ca toate datele să fie
        introduse corect și fără erori. Clientul trebuie să înțeleagă că, în
        cazul în care datele furnizate sunt incorecte sau incomplete, procesul
        de comandă poate fi afectat, iar plățile efectuate nu vor fi returnate.
      </p>
      <p>
        10.2. Deși în fiecare moment face eforturi în vederea asigurării
        calității și corectitudinii mesajelor publicate în site, Prestatorul nu
        poate garanta, expres sau implicit, în privința conținutului, a
        site-ului ori serviciilor publicate.
      </p>

      <h2>11. Încetarea Contractului</h2>
      <p>11.1. Prezentul Document încetează în următoarele cazuri:</p>
      <ul>
        <li>părțile convin de comun acord încetarea contractului;</li>
        <li>
          prin decizia unilaterală a uneia dintre părți, transmisă în scris
          celeilalte părți;
        </li>
        <li>în caz de dizolvare, lichidare, faliment.</li>
      </ul>
      <p>
        11.2. Părțile sunt de acord că încetarea prezentului contract reprezintă
        și stingerea/anularea tuturor obligațiilor deja scadente între părțile
        contractante.
      </p>
      <p>
        11.3. Încetarea contractului dintre Client și Prestator din oricare
        dintre motivele de mai sus are drept consecință și încetarea
        Contractului de Asistență juridică și a mandatului de reprezentare dat
        societății civile de avocați PETRE ROSU SI ASOCIATII.
      </p>

      <h2>12. Exonerarea de Răspundere</h2>
      <p>
        12.1. Prestatorul nu va răspunde pentru niciun prejudiciu material sau
        moral, orice pagubă sau costuri ce pot interveni din acțiunile ce sunt
        în sarcina Clientului, precum și încălcarea de către acesta a oricăror
        obligații legale, dacă această încălcare nu se datorează culpei
        Prestatorului.
      </p>

      <h2>13. Forța Majoră și Cazul Fortuit</h2>
      <p>
        13.1. Exceptând cazurile în care nu au prevăzut în mod expres altfel,
        niciuna dintre părțile unui contract încheiat, care este încă în
        derulare, nu va fi răspunzătoare pentru neexecutarea la termen și/sau în
        mod corespunzător, total sau parțial, a oricăreia din obligațiile care
        îi incumbă în baza contractului, dacă neexecutarea obligației respective
        a fost cauzată de un eveniment de forță majoră.
      </p>
      <p>
        13.2. Partea sau reprezentantul legal al părții care invocă evenimentul
        mai sus menționat este obligată să aducă la cunoștința celeilalte,
        imediat și în mod complet, producerea acestuia și să ia orice măsuri
        care-i stau la dispoziție în vederea limitării consecințelor
        respectivului eveniment.
      </p>
      <p>
        13.3. Partea sau reprezentantul legal al părții care invocă evenimentul
        mai sus menționat este exonerat de la această obligație numai în cazul
        în care evenimentul o împiedică să o ducă la bun sfârșit.
      </p>
      <p>
        13.4. Dacă în termen de 72 de ore de la data producerii lui, respectivul
        eveniment nu încetează, fiecare parte va avea dreptul să notifice
        celeilalte părți încetarea deplin drept a contractului fără ca vreuna
        dintre ele să poată pretinde celeilalte alte daune-interese.
      </p>
      <p>
        13.5. Partea care invocă evenimentul de forță majoră trebuie să facă
        dovada imposibilității executării obligațiilor în termen de 72 de ore de
        la data producerii evenimentului, dar numai în cazul în care evenimentul
        o împiedică să o ducă la bun sfârșit.
      </p>

      <h2>14. Competența</h2>
      <p>
        14.1. Contractul va fi guvernat de legea română și se interpretează în
        conformitate cu reglementările cu privire la aceasta. Orice neînțelegeri
        se soluționează pe cale amiabilă. În cazul în care neînțelegerile nu pot
        fi rezolvate pe cale amiabilă, ele vor fi supuse instanțelor
        judecătorești de la sediul Prestatorului.
      </p>

      <h2>15. Drepturi de Autor</h2>
      <p>
        15.1. Design-ul, textele, grafica, structura și toate informațiile de pe
        Website sunt proprietatea exclusivă a RENEGADE GRIGORE S.R.L. cu sediul
        în București, sector 1, Str. Stanilesti, Nr. 56, Cam. 1, având CIF
        ___________ și _________. Orice încercare de a copia, reproduce,
        republica, transfera date, afișa, distribui, vinde sau modifica din
        conținutul, datele, informațiile și materialele găsite este strict
        interzisă.
      </p>

      <h2>
        16. Confidențialitatea și Prelucrarea Datelor cu Caracter Personal
      </h2>
      <p>
        16.1. Părțile se obligă să păstreze confidențialitatea informațiilor de
        care iau cunoștință pe parcursul derulării prezentului contract și după
        încetarea contractului. Beneficiarul înțelege și acceptă faptul că
        pentru executarea obligațiilor asumate prin Contract este necesară
        dezvăluirea și prelucrarea unor date cu caracter personal, precum nume,
        prenume, adresa de e-mail, număr de telefon, carte de
        identitate/pașaport, permis de muncă, numele membrilor de familie (tată,
        mamă), semnătură etc.
      </p>
      <p>
        16.2. Prestatorul va prelucra datele cu caracter personal într-un mod
        confidențial și responsabil. Prelucrarea datelor cu caracter personal se
        efectuează în conformitate cu Regulamentul General de Protecție a
        Datelor („GDPR”) și cu legislația română și europeană privind protecția
        datelor cu caracter personal.
      </p>
      <p>
        16.3. Prelucrarea datelor cu caracter personal se va face în condiții de
        legalitate, echitate și transparență, cu asigurarea securității adecvate
        a datelor, inclusiv protecția împotriva prelucrării neautorizate sau
        ilegale și împotriva pierderii, adistrugerii sau a deteriorării
        accidentale și potrivit Politicii de confidențialitate afișată pe
        website-ul Prestatorului. Personalul Prestatorului respectă cu strictețe
        cerințele legale în privința protecției datelor cu caracter personal și
        are grijă ca toate operațiunile de prelucrare să fie realizate numai în
        interesul Clientului.
      </p>

      <h2>17. Reclamații</h2>
      <p>
        17.1. Pentru orice tip de reclamații, Clientul va contacta Prestatorul
        la adresa de email: contact@eliberariacte.ro. Echipa Prestatorului va
        face tot posibilul să rezolve în cel mai scurt timp posibil
        reclamațiile.
      </p>

      <h2>18. Dispoziții Finale</h2>
      <p>
        18.1. Părțile declară că au negociat toate clauzele prezentelor Condiții
        și acestea sunt acceptate în mod expres de către Client în momentul
        creării contului, orice înțelegere anterioară neproducând efecte
        juridice între acestea.
      </p>
      <p>
        18.2. Prestatorul își rezervă dreptul de a actualiza și modifica
        periodic aceste Condiții. Clientul este de acord ca forma modificată a
        acestor Condiții va intra în vigoare la data la care noile Condiții vor
        fi publicate pe Website. Clientul va verifica periodic conținutul
        acestui document. Prestatorul va încerca, atât cât este posibil în mod
        rezonabil, să informeze Clientul prin email despre modificarea
        Condițiilor.
      </p>
      <p>
        18.3. Prezentele Condiții se completează, în ceea ce privește
        prelucrarea datelor cu caracter personal, cu politica de
        confidențialitate și cu politica de utilizare cookies disponibile pe
        Website.
      </p>
      <p>
        18.4. Orice notificare către Prestator trebuie trimisă electronic la
        adresa de email contact@eliberariacte.ro indicată în preambulul
        prezentului Document.
      </p>
    </div>
  );
};

export default TermeniSiConditii;
