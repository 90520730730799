import React from "react";
import "../style/privacy.css";

const PoliticaDeConfidentialitate = () => {
  return (
    <div>
      <div className="privacy-container">
        <h1>Politica de Confidențialitate</h1>

        <h2>1. Generale</h2>
        <p>
          1.1. Societatea noastră, RENEGADE GRIGORE SRL („Compania” sau
          „Operatorul”), având sediul în București, Sector 1, str. Stănilețti,
          nr. 56, procesăm datele dumneavoastră cu caracter personal ca parte a
          utilizării de către dumneavoastră a produselor noastre („Produse”) sau
          platformei („Platformă” și, împreună cu Produsele, „Servicii”).
          Prelucrarea datelor dumneavoastră cu caracter personal se efectuează
          în conformitate cu Regulamentul General de Protecție a Datelor
          („GDPR”) și cu legislația română și europeană privind protecția
          datelor cu caracter personal.
        </p>
        <p>
          1.2. Prelucrarea datelor cu caracter personal se face în condiții de
          legalitate, echitate și transparență, cu asigurarea securității
          adecvate a datelor, inclusiv protecția împotriva prelucrării
          neautorizate sau ilegale și împotriva pierderii, a distrugerii sau a
          deteriorării accidentale. Personalul nostru și colaboratorii respectă
          cu strictețe cerințele legale în privința protecției datelor cu
          caracter personal și se îngrijesc ca toate operațiunile de prelucrare
          să fie realizate numai în interesul dumneavoastră.
        </p>
        <p>
          1.3. În această Politică de confidențialitate dorim să vă oferim
          informații despre Companie, natura, domeniul de aplicare și scopurile
          colectării și utilizării datelor, pentru a vă oferi o perspectivă
          asupra prelucrării datelor dumneavoastră cu caracter personal.
        </p>
        <p>
          1.4. Pentru Produsele noastre, vom procesa datele dumneavoastră numai
          în conformitate cu scopurile și mijloacele pe care le determinați. În
          aceste cazuri, vă vom furniza acorduri separate de prelucrare a
          datelor cu caracter personal.
        </p>
        <p>
          1.5. Operatorul pentru prelucrarea datelor dumneavoastră cu caracter
          personal în sensul GDPR este Compania. Ne puteți contacta la adresa de
          corespondență de mai jos sau prin e-mail folosind adresa de e-mail
          contact@eliberariacte.ro.
        </p>

        <h2>2. Despre noi</h2>
        <p>
          Operatorul: RENEGADE GRIGORE SRL
          <br />
          Sediul social: Bucureşti, Sector 1, nr. 56, cam.1
          <br />
          Număr de înregistrare la Registrul Comerţului de pe lângă Tribunalul
          București: J40/7878/2020
          <br />
          Cod unic de înregistrare fiscală: 42763857
          <br />
          E-mail: contact@eliberariacte.ro
          <br />
          Datele de contact ale responsabilului cu protecția datelor:
          <br />
          Adresa de corespondență: Str. Stănilești nr. 56, sector 1, Cam. 1,
          București
          <br />
          E-mail: dpo@eliberariacte.ro
        </p>

        <h2>3. Principii de prelucrare a datelor cu caracter personal</h2>
        <p>
          3.1. Pentru orice prelucrare a datelor cu caracter personal efectuată
          vă asigurăm că datele dumneavoastră sunt:
        </p>
        <ul>
          <li>prelucrate în mod legal, echitabil și transparent;</li>
          <li>
            colectate în scopuri determinate, explicite și legitime și nu sunt
            prelucrate ulterior într-un mod incompatibil cu aceste scopuri,
            prelucrarea ulterioară în scopuri de arhivare în interes public, în
            scopuri de cercetare științifică sau istorică ori în scopuri
            statistice nu este considerată incompatibilă cu scopurile inițiale;
          </li>
          <li>
            adecvate, relevante și limitate la ceea ce este necesar în raport cu
            scopurile în care sunt prelucrate;
          </li>
          <li>
            exacte și, în cazul în care este necesar, actualizate; în acest sens
            vom lua toate măsurile necesare pentru a ne asigura că datele cu
            caracter personal care sunt inexacte, având în vedere scopurile
            pentru care sunt prelucrate, sunt șterse sau rectificate fără
            întârziere;
          </li>
          <li>
            păstrate într-o formă care permite identificarea dumneavoastră pe o
            perioadă care nu depășește perioada necesară îndeplinirii scopurilor
            în care sunt prelucrate datele; datele cu caracter personal vor fi
            stocate pe perioade mai lungi în măsura în care acestea vor fi
            prelucrate exclusiv în scopuri de arhivare în interes public, în
            scopuri de cercetare științifică sau istorică ori în scopuri
            statistice;
          </li>
          <li>
            prelucrate într-un mod care asigură securitatea adecvată, inclusiv
            protecția împotriva prelucrării neautorizate sau ilegale și
            împotriva pierderii, a distrugerii sau a deteriorării accidentale,
            prin luarea de măsuri tehnice sau organizatorice corespunzătoare.
          </li>
        </ul>
        <p>
          3.2. Datele dumneavoastră cu caracter personal vor fi păstrate într-o
          formă care permite identificarea dumneavoastră pe o perioadă care nu
          depășește perioada necesară îndeplinirii scopurilor în care sunt
          prelucrate datele. După această perioadă, datele dumneavoastră cu
          caracter personal vor fi șterse cu excepția cazului în care este
          permisă stocarea acestora în condițiile legii.
        </p>

        <h2>4. Datele pe care le prelucrăm</h2>
        <p>
          4.1. Date de identificare: Prelucrăm astfel de date cu caracter
          personal pe care dumneavoastră, în calitate de utilizator al
          Serviciilor, ni le puneți la dispoziție, de exemplu la înregistrare
          sau atunci când utilizați Serviciile („Datele”). Datele de
          identificare pot fi: nume, prenume, adresa de domiciliu, data
          nașterii, locul nașterii, cetățenie, CNP, informații din actul de
          identitate, semnătura, naționalitate, sex, date biometrice, date
          privind membrii de familie. Date de contact: număr de telefon, adresa
          de e-mail, adresă de domiciliu sau reședință.
        </p>
        <p>
          4.2. Utilizarea site-ului web: Dacă vizitați site-ul nostru web,
          procesăm numai datele personale pe care browserul dumneavoastră le
          comunică serverului nostru. Colectăm următoarele date, care ne sunt
          necesare pentru a afișa corect site-ul și pentru a garanta
          stabilitatea și securitatea:
        </p>
        <ul>
          <li>Adresa IP;</li>
          <li>marca de dată și oră, diferența de timp față de GMT;</li>
          <li>
            site-ul solicitat, site-ul de pe care a fost trimisă solicitarea,
            volumul de date transmis;
          </li>
          <li>starea accesului/codul de stare HTTP;</li>
          <li>
            browser, operating system, interface, language and version of the
            browser software.
          </li>
        </ul>
        <p>
          4.3. Date de înregistrare: La înregistrare colectăm și procesăm
          următoarele informații:
        </p>
        <ul>
          <li>detalii înregistrare: data înregistrării, parola;</li>
          <li>
            informații personale: nume complet; adresa de e-mail; adresa, cod
            poștal, oraș, regiune, țară;
          </li>
          <li>
            informații despre companie (când este cazul): numele companiei,
            site-ul web, codul fiscal, adresa sediului;
          </li>
          <li>informații de plată;</li>
          <li>
            tipul cardului de credit, ultimele patru cifre, data expirării;
          </li>
          <li>adresa de e-mail PayPal;</li>
          <li>data plății, codul facturii, moneda, suma;</li>
          <li>plata lunară sau anuală.</li>
        </ul>
        <p>
          4.4. Date de utilizare a produsului: Datele prelucrate în timpul
          utilizării Serviciilor sunt prelucrate de noi numai ca procesator, nu
          ca operator de date cu caracter personal.
        </p>

        <h2>5. De ce prelucrăm datele dumneavoastră</h2>
        <p>
          5.1. Scop: Datele cu caracter personal sunt prelucrate pentru
          următoarele scopuri („Scopuri”):
        </p>
        <ul>
          <li>
            Prestarea unor servicii: prelucrarea este necesară pentru executarea
            unui contract sau pentru a face demersuri înainte de încheierea unui
            contract;
          </li>
          <li>
            Îndeplinirea obligațiilor legale: prelucrarea este necesară în
            vederea îndeplinirii unei obligații legale (de exemplu:
            contabilitate, evidență financiară și arhivare);
          </li>
          <li>
            Gestionarea relațiilor cu clienții, inclusiv buletine informative;
          </li>
          <li>Securitatea și stabilitatea Serviciilor.</li>
        </ul>
        <p>
          5.2. Legalitatea prelucrării: legalitatea prelucrării (Art. 6 GDPR)
          decurge din:
        </p>
        <ul>
          <li>
            5.2.1. consimțământul dvs, în cazul în care v-am solicitat
            consimțământul explicit (alin.1 lit. a) din GDPR);
          </li>
          <li>
            5.2.2. necesitatea îndeplinirii contractului, deoarece datele
            dumneavoastră sunt necesare pentru o utilizare satisfăcătoare a
            Serviciilor (art. 1 lit. b) din GDPR);
          </li>
          <li>
            5.2.3. prelucrarea este necesară în vederea îndeplinirii unei
            obligații legale care îi revine Companiei (art. 1 lit. c) din GDPR);
          </li>
          <li>
            5.2.4. interesele legitime urmărite de Companie sau de un terț
            (alin. 1 lit. f ) din GDPR).
          </li>
        </ul>

        <h2>6. Categoriile de destinatari ai datelor cu caracter personal</h2>
        <p>
          6.1. Utilizare: Folosim Datele pe care dumneavoastră, în calitate de
          utilizator al produsului, ni le-ați furnizat, numai în scopuri
          descrise. În anumite situații punctuale vom apela la parteneri
          externi. Prelucrarea datelor cu caracter personal de către partenerii
          externi ai noștri se va face doar dacă aceștia oferă garanții
          suficiente pentru punerea în aplicare a unor măsuri tehnice și
          organizatorice adecvate, astfel încât prelucrarea să respecte
          cerințele prevăzute în GDPR și să asigure protecția drepturilor
          persoanei vizate.
        </p>
        <ul>
          <li>
            Instituții publice și autorități publice: Prelucrarea datelor cu
            caracter personal în aceste cazuri se va face pentru îndeplinirea
            unei sarcini care servește unui interes public sau care rezultă din
            exercitarea autorității publice și în baza unor reglementări legale
            speciale.
          </li>
          <li>
            Avocați, auditori sau alți consultanți sau prestatori de servicii
            externi: Prelucrarea datelor de către aceste categorii profesionale
            se va face doar cu respectarea strictă a secretului profesional și a
            confidențialității datelor cu caracter personal.
          </li>
        </ul>
        <p>
          6.2. Transfer: transmitem Datele către terți numai, dacă acest lucru
          este (i) necesar pentru Scopuri pentru care au fost colectate, de ex.
          atunci când folosim furnizori de servicii, (ii) pentru a răspunde unei
          solicitări din partea unei autorități naționale, (iii) din cauza unei
          hotărâri judecătorești sau (iv) dacă ați consimțit în prealabil.
        </p>
        <p>6.3. Prestatori de servicii:</p>
        <ul>
          <li>
            6.3.1. Pentru unele părți ale Serviciilor noastre, folosim furnizori
            terți pentru a prelucra date pentru noi, cum ar fi Stripe Payments
            Limited;
          </li>
          <li>
            6.3.2. Atunci când utilizați unii dintre furnizorii de servicii,
            datele sunt transferate către destinatari din țări terțe, și anume
            SUA.
          </li>
        </ul>

        <h2>7. Refuzul de a ne furniza datele</h2>
        <p>
          Vom prelucra datelor cu caracter personal doar în condiții de
          legalitate, respectând cu strictețe principiile legate de prelucrarea
          datelor cu caracter personal stabilite de legislația națională și
          europeană. Nu aveți obligația de a ne furniza datele dumneavoastră cu
          caracter personal însă refuzul acestuia va conduce la imposibilitatea
          accesării serviciilor noastre.
        </p>

        <h2>8. Inexistența unui proces decizional automatizat</h2>
        <p>
          Datele cu caracter personal precum și oricare dintre operațiunile de
          procesare din cadrul Serviciilor noastre nu includ un proces
          decizional bazat exclusiv pe prelucrarea automată a datelor, inclusiv
          crearea de profiluri.
        </p>

        <h2>9. Ștergerea și siguranța datelor</h2>
        <h3>9.1. Ștergere</h3>
        <p>
          Datele vor fi șterse dacă (i) vă revocați consimțământul și nu există
          alt temei de prelucrare sau o obligație legală din partea noastră de a
          stoca datele; (ii) Datele nu mai sunt necesare pentru a îndeplini
          contractul de utilizare privind Produsul sau (iii) stocarea este sau
          devine nepermisă din punct de vedere legal. O solicitare de ștergere
          nu afectează Datele, dacă stocarea este necesară din punct de vedere
          legal, de exemplu în scopuri contabile.
        </p>
        <h3>9.2. Măsuri de siguranță</h3>
        <p>
          Pentru a evita accesul neautorizat la Date și, în general, a securiza
          Datele, aplicăm următoarele măsuri de siguranță: transmisie criptată,
          stocare criptată, un concept de autorizare, un concept de copiere de
          rezervă a datelor și măsuri de siguranță fizică pentru servere. Aceste
          măsuri de siguranță sunt revizuite în mod constant pentru a se
          conforma celor mai recente evoluții tehnologice.
        </p>

        <h2>10. Perioada de stocare a datelor cu caracter personal</h2>
        <p>
          10.1. Compania asigură confidențialitatea datelor cu caracter personal
          prelucrate în conformitate cu acordul exprimat de dumneavoastră și
          conform prevederilor legale. Accesul la informațiile tratate drept
          confidențiale va fi limitat la acele persoane, care prin natura
          activității desfășurate, este necesar să ia cunoștință de aceste
          informații în scopul ducerii la îndeplinire a scopului, raporturilor
          juridice născute în relație cu Operatorul. Aceste persoane sunt ținute
          să respecte caracterul confidențial al acestor informații, asumându-și
          la rândul lor obligația de a asigura și păstra confidențialitatea
          acestor date și informații și de a le prelucra în conformitate cu
          cerințele legale.
        </p>
        <ul>
          <li>
            Datele care sunt necesare în scopuri legate de serviciile oferite
            vor fi stocate pe durata contractului de prestări servicii,
            respectiv pe perioada de timp necesară în vederea îndeplinirii
            obligațiilor legale;
          </li>
          <li>
            Datele legate de plăți/facturare vor fi stocate pe o perioadă de 10
            ani, conform Legii nr. 82/1991 privind contabilitatea;
          </li>
          <li>
            Prelucrarea datelor în scop de marketing va avea loc pe durata
            relației contractuale cu Operatorul, precum și după încetarea
            acesteia. În situația în care dumneavoastră vă retrageți
            consimțământul privind marketing direct, datele nu vor mai fi
            prelucrate în acest scop, din momentul retragerii consimțământului.
          </li>
          <li>
            De asemenea, datele dumneavoastră pot fi prelucrate și pe durata
            existenței unei obligații legale pentru păstrarea datelor, respectiv
            pe durata de existență a unui alt temei justificativ legal, în
            conformitate cu exigențele art. 6 din GDPR.
          </li>
        </ul>

        <h2>11. Informații despre drepturi</h2>
        <ul>
          <li>
            <strong>Dreptul de access:</strong> Aveți dreptul de a obține din
            partea noastră o confirmare că Operatorul prelucrează sau nu datele
            dumneavoastră cu caracter personal iar în caz afirmativ, acces la
            datele respective și la anumite informații stabilite de GDPR.
          </li>
          <li>
            <strong>Dreptul la rectificarea datelor:</strong> Aveți dreptul de a
            obține din partea noastră rectificarea datelor cu caracter personal
            inexacte. Ţinându-se seama de scopurile în care au fost prelucrate
            datele, aveți dreptul de a obţine completarea datelor cu caracter
            personal care sunt incomplete, inclusiv prin furnizarea unei
            declaraţii suplimentare.
          </li>
          <li>
            <strong>
              Dreptul la ștergerea datelor („dreptul de a fi uitat”):
            </strong>{" "}
            Cunoscut în special ca „dreptul de a fi uitat“, acesta se bazează pe
            principiul de a garanta oricărui individ libertatea de a face ce
            vrea cu datele sale personale, inclusiv de a le șterge, dacă nu
            există un motiv convingător sau special pentru continuarea
            procesării și stocării acestora.
          </li>
          <li>
            <strong>Dreptul la restricționarea prelucrării:</strong>{" "}
            Restricționarea prelucrării înseamnă marcarea datelor cu caracter
            personal stocate cu scopul de a limita prelucrarea viitoare a
            acestora.
          </li>
          <li>
            <strong>Dreptul la portabilitatea datelor:</strong> Scopul acestui
            drept este de a vă oferi posibilitatea efectivă de a avea mai mult
            control asupra datelor cu caracter personal. Dreptul de
            portabilitate a datelor se aplică numai în cazul în care: datele
            sunt procesate prin mijloace automate; dumneavoastră v-ați dat
            consimțământul pentru prelucrare; prelucrarea este necesară pentru a
            îndeplini condițiile stipulate printr-un contract.
          </li>
          <li>
            <strong>Dreptul de opoziție:</strong> Aveți dreptul de a vă opune
            atunci când prelucrarea datelor personale se face în scopuri de
            marketing direct; pentru realizarea de profiluri; prin mijloace
            automate; sau în scopuri științifice sau istorice.
          </li>
          <li>
            <strong>
              Dreptul de a nu fi supus unor decizii automatizate, inclusiv
              profilarea:
            </strong>{" "}
            Pentru a asigura conformitatea cu acest drept, Compania va
            identifica permanent dacă oricare dintre operațiunile procesare
            include un proces automat de luare a deciziilor și va actualiza
            procedurile în consecință pentru a răspunde cerințelor GDPR.
          </li>
          <li>
            <strong>
              Dreptul de a vă retrage consimțământul în orice moment, fără a
              afecta legalitatea prelucrării efectuate pe baza consimțământului
              înainte de retragerea acestuia:
            </strong>{" "}
            în anumite condiții expres prevăzute de legislația națională și
            europeană, precum și dreptul de a depune o plângere în fața unei
            autorități de supraveghere.
          </li>
        </ul>
        <p>
          Pentru a vă exercita oricare dintre drepturi menționate mai sus sau
          pentru a transmite orice fel de întrebare sau nelămurire privind
          aspectele menționate în prezenta Politică de confidențialitate, vă
          puteți adresa folosind datele de contact menționate la începutul
          acestui document.
        </p>

        <h2>12. Cookie-uri</h2>
        <h3>12.1. Ce sunt cookie-urile?</h3>
        <p>
          Site-ul web folosește „cookie-uri” – fișiere text mici care sunt
          plasate pe computerul utilizatorului, smartphone-ul și/sau stocate de
          browser. Dacă serverul respectiv al site-ului nostru este din nou
          accesat de către utilizatorul site-ului/produsului, browserul
          utilizatorului trimite cookie-ul primit mai sus înapoi la server.
          Serverul poate evalua informațiile primite în acest mod în diferite
          moduri. Cookie-urile pot fi utilizate, de exemplu, pentru a gestiona
          reclamele pe site sau pentru a facilita navigarea pe o pagină web.
        </p>
        <h3>12.2. Dezactivarea cookie-urilor</h3>
        <p>
          Utilizatorul poate dezactiva instalarea cookie-urilor introducând
          setările corespunzătoare în software-ul browserului său (de exemplu,
          în Google chrome, Internet Explorer, Mozilla Firefox, Opera sau
          Safari). Cu toate acestea, în acest caz, utilizatorul poate pune în
          pericol utilizarea întregii game de funcții de pe site.
        </p>
        <h3>12.3. Politica privind cookie-urile</h3>
        <p>
          Vă rugăm să consultați Politica privind cookie-urile pentru mai multe
          informații.
        </p>

        <h2>13. Servicii de analiză</h2>
        <h3>13.1. Google Analytics</h3>
        <h4>13.1.1. General</h4>
        <p>
          Acest site web utilizează Google Analytics, un serviciu de analiză web
          al Google Inc. („Google”). Google Analytics folosește cookie-uri.
          Informațiile despre utilizarea de către dvs. a site-ului web create de
          un cookie, vor fi de obicei transmise unui server Google din SUA și
          stocate acolo. Site-ul web folosește Google Analytics împreună cu
          opțiunea „_anonymizeIP()”. Aceasta înseamnă că adresa dumneavoastră IP
          va fi scurtată de Google în prealabil în statele membre ale UE sau în
          alte state membre ale Acordului privind Spațiul Economic European.
          Doar în cazuri excepționale, întreaga adresă IP va fi transmisă unui
          server Google din SUA și scurtată acolo. Prin urmare, orice legătură
          cu o anumită persoană este exclusă și datele personale sunt șterse
          imediat. Adresa IP transmisă de browserul dumneavoastră în cadrul
          utilizării Google Analytics nu va fi combinată cu alte date deținute
          de Google.
        </p>
        <h4>13.1.2. Plug-in</h4>
        <p>
          Puteți împiedica colectarea de date prin intermediul cookie-ului cu
          privire la utilizarea de către dvs. a site-ului web (inclusiv adresa
          dumneavoastră IP), precum și prelucrarea de către Google a acestor
          date, prin descărcarea și instalarea următorului plug-in de browser:
          <a href="https://tools.google.com/dlpage/gaoptout?hl=ro">
            https://tools.google.com/dlpage/gaoptout?hl=ro
          </a>
          .
        </p>
        <h4>13.1.3. Scop</h4>
        <p>
          În numele operatorului site-ului web, Google va folosi aceste
          informații pentru a evalua utilizarea site-ului web, pentru a alcătui
          rapoarte despre activitățile site-ului web și pentru a oferi
          operatorului servicii suplimentare legate de utilizarea site-ului web
          și a internetului. Utilizăm Google Analytics pentru a analiza și a
          putea îmbunătăți în mod constant utilizarea site-ului nostru web. Prin
          statistici putem îmbunătăți serviciile noastre și le facem mai
          interesante pentru utilizatori. În acele cazuri speciale în care
          datele personale sunt transmise în SUA, Google este certificat prin
          scutul de confidențialitate UE-SUA. Baza prelucrării este articolul 6
          alin. 1 alin. f GDPR.
        </p>
        <h4>13.1.4. Informații despre furnizorul terță parte</h4>
        <p>
          Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street,
          Dublin 4, Ireland, Fax: +353 (1) 436 1001;
          <br />
          Termeni:{" "}
          <a href="https://www.google.com/analytics/terms/gb.html">
            https://www.google.com/analytics/terms/gb.html
          </a>
          ;
          <br />
          Prezentare generală privind protecția datelor:{" "}
          <a href="https://support.google.com/analytics/answer/6004245?hl=ro">
            https://support.google.com/analytics/answer/6004245?hl=ro
          </a>
          ;
          <br />
          Politica de confidențialitate:{" "}
          <a href="https://www.google.de/intl/en/policies/privacy/">
            https://www.google.de/intl/en/policies/privacy/
          </a>
          .
        </p>

        <h2>14. Modificări ale acestei politici de confidențialitate</h2>
        <p>
          Serviciile noastre sunt dinamice și introducem frecvent
          funcționalități noi, ceea ce poate necesita colectarea de informații
          noi. În cazul în care Compania decide să modifice această Politică de
          confidențialitate, va posta acele modificări direct în Servicii.
        </p>
      </div>
    </div>
  );
};

export default PoliticaDeConfidentialitate;
